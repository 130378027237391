import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { toast } from "react-toastify";
import * as meteringApi from "../../services/meteringApi";
var ls = require("local-storage");

export const BalanceForm = () => {
  // ls("test", "1234");
  // alert(ls.get("test"));

  const [loading, setLoading] = useState(false);
  const [useAccountNumber, setUseAccountNumber] = useState(false);
  const [account, setAccount] = useState(
    ls.get("account") ? ls.get("account") : ""
  );
  const [meter, setMeter] = useState(
    ls.get("meterNumber") ? ls.get("meterNumber") : ""
  );
  const [balance, setBalance] = useState("");

  const isAccountValid = () => {
    return account.length === 8;
  };

  const isMeterValid = () => {
    return meter.length >= 7;
  };

  const isFormReady = () => {
    return useAccountNumber ? isAccountValid() : isMeterValid();
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // stop page from resetting
    if (!isFormReady()) {
      e.stopPropagation();
    } else {
      getBalance();
    }
  };

  const getBalance = async function () {
    setBalance("");
    setLoading(true);

    let resp;
    try {
      if (useAccountNumber) {
        resp = await meteringApi.getBalanceByAccount(account);
      } else {
        resp = await meteringApi.getBalanceBySerial(meter);
      }

      // errors are returned in a list
      if (Array.isArray(resp.data)) {
        toast.error(resp.data[0].error);
      } else {
        setBalance(resp.data.balance + " NGN"); // TODO update backend to send currency
        ls("balance", resp.data.balance);
        useAccountNumber ? ls("account", account) : ls("meterNumber", meter);
      }
    } catch (e) {
      console.error(e);
      toast.error("An unknown error occured, please try again");
    }
    setLoading(false);
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <ButtonGroup className="my-3" size="lg">
        <ToggleButton
          id="toggle-meter"
          type="radio"
          variant="outline-primary"
          value="meter"
          checked={!useAccountNumber}
          onChange={(e) => setUseAccountNumber(false)}
        >
          {" Meter Number"}
        </ToggleButton>
        <ToggleButton
          id="toggle-account"
          type="radio"
          variant="outline-primary"
          value="account"
          checked={useAccountNumber}
          onChange={() => setUseAccountNumber(true)}
        >
          {" Account Number"}
        </ToggleButton>
      </ButtonGroup>

      {useAccountNumber ? (
        <Form.Group controlId="account" className="mb-5">
          <Form.Control
            size="lg"
            required
            type="text"
            placeholder="Enter account number here"
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
            }}
            isValid={isAccountValid()}
            isInvalid={!isAccountValid()}
          />
          <Form.Control.Feedback type="invalid">
            {"Account number should be 8 digits"}
          </Form.Control.Feedback>
        </Form.Group>
      ) : (
        <Form.Group controlId="meter" className="mb-5">
          <Form.Control
            size="lg"
            required
            type="text"
            placeholder="Enter meter number here"
            value={meter}
            onChange={(e) => {
              setMeter(e.target.value);
            }}
            isValid={isMeterValid()}
            isInvalid={!isMeterValid()}
          />
          <Form.Control.Feedback type="invalid">
            {"Meter number should have at least 7 letters"}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Row>
        <Col>
          <Button
            type="submit"
            size="lg"
            block={true}
            disabled={loading || !isFormReady()}
            className="my-3"
          >
            {loading ? "Loading.." : "Submit"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="my-2">
            <Form.Control
              size="lg"
              type="text"
              placeholder="Balance"
              disabled={true}
              value={balance}
              isValid={balance.length > 0}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};
