import "./App.scss";
import React, {useEffect} from "react";
import {ToastContainer, toast} from "react-toastify";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {Container, Navbar, Tabs, Tab} from "react-bootstrap";
import {PaymentForm} from "./components/payment/paymentForm";
import {BalanceForm} from "./components/balance/balanceForm";
import {TabTokenForm} from "./components/payment/TokenForm";
import ReactGA from "react-ga";

function App() {
  // https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
  // https://github.com/react-ga/react-ga/issues/336
  const trackingId = "UA-205850358-2";
  ReactGA.initialize(trackingId, {testMode: process.env.NODE_ENV === "test"});

  // show toast notification to reopen app after update is downloaded
  // to allow update without closing app, use this: https://felixgerschau.com/create-a-pwa-update-notification-with-create-react-app/
  const onUpdate = () => {
    toast.info(
      "A new update has been downloaded. Close and re-open the app to install the update."
    );
  };

  useEffect(() => {
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({onUpdate: onUpdate});

    //log page view when first opened
    ReactGA.set({page: "amini_mobile"});
    ReactGA.pageview("amini_mobile");
    console.log(`Page View logged for: "amini_mobile"`);
  }, []);

  return (
    <Container className="app">
      <Navbar bg="primary" variant="dark" expand="lg" fixed="top">
        <Navbar.Brand> Amini Mobile</Navbar.Brand>
      </Navbar>
      <ToastContainer position="top-center" autoClose={false} newestOnTop />
      <Tabs id="payment-tabs" defaultActiveKey="payment" className="mb-3">
        <Tab eventKey="payment" title="Payment">
          <PaymentForm />
        </Tab>
        <Tab eventKey="token" title="Token">
          <TabTokenForm />
        </Tab>
        <Tab eventKey="balance" title="Balance">
          <BalanceForm />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default App;
