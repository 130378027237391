import axios from "axios";

const billingApi = axios.create();
billingApi.defaults.withCredentials = true;
billingApi.defaults.validateStatus = (status) => {
  return status <= 400; // don't throw error with 400 or below
};
billingApi.defaults.headers = {
  Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
};

const billing_url =
  process.env.REACT_APP_IS_TEST === "0"
    ? "https://ng.powergen-re.net/billing/api"
    : "https://test.powergen-re.net/billing/api";

const payment_gateway_url =
  process.env.REACT_APP_IS_TEST === "0"
    ? "https://ng.powergen-re.net/payment_gateway/api"
    : "https://test.powergen-re.net/payment_gateway/api";

// docker/CI-CD
// routing
// endpoint for flutterwave validate

export function getAccount(accountNumber) {
  return billingApi.get(`${billing_url}/accounts`, {
    params: { accountno: accountNumber },
  });
}

export function getTransaction(reference) {
  return billingApi.get(`${billing_url}/payments`, {
    params: { search: reference },
  });
}

export function getAccountBySerial(meter_serial) {
  return billingApi.get(`${billing_url}/accounts`, {
    params: { meter_serial },
  });
}

export function validateFlutterwave(transactionData) {
  return billingApi.post(`${payment_gateway_url}/flutterwave/validate`, {
    phone_number: transactionData.phoneNumber,
    account_number: transactionData.accountNumber,
    amount: transactionData.amount,
    tx_ref: transactionData.ref,
  });
}
