import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { copyToClipboard } from "../../services/clipboard";
import { getTransaction } from "./../../services/billingApi";
var ls = require("local-storage");

export const TabTokenForm = ()=>{
  const [message, setMessage] = useState("");
  const [_, setShow] = useState(true);

  if (message.length > 0) {
    return (
      <>
        <p>{message}</p>
        <Button onClick={()=>setMessage("")}>OK</Button>
      </>
    );
  }

  return(
  <TokenForm setShow={setShow} setMessage={setMessage}/>
  )
}

export const TokenForm = ({ setShow, setMessage }) => {
  const [reference, setReference] = useState(ls.get("reference"));
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Copy Token");
  const copied = "Copied"
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setToken("");
    try {
      const resp = await getTransaction(reference);
      const data = resp.data.data;
      if (data?.length > 0 && data[0].token) {
        const token = data[0].token;
        setToken(token);
      } else {
        setMessage(
          "Token has not be generated for this transaction, try again soon. If this persists, contact PowerGen Support",
        );
      }
    } catch (exp) {
      setMessage("Failed to fetch token, if this persists, contact PowerGen Support");
    }
    setLoading(false);
  };

  const goBack = () => {
    setShow(false);
    setMessage("");
  };
  const copyToken = () => {
    copyToClipboard(token);
    setButtonLabel(copied);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Fetch Token From Last Transaction</Form.Label>
        <Form.Control
          required
          type="text"
          disabled={true}
          onChange={(e) => {
            setReference(e.target.value);
          }}
          value={`Transaction Reference: ${reference}`}
        />
      </Form.Group>
      {token && (
        <Row>
          <Col xs={7} md={4}>
            <span>{`Token: ${token}`}</span>
          </Col>
          <Col xs={4}>
            <Button variant={buttonLabel===copied?"dark":"primary"}  onClick={copyToken}>{buttonLabel}</Button>
          </Col>
        </Row>
      )}
      <Button variant="secondary"  className="mr-3 my-3" onClick={goBack}>Back</Button>
      <Button type="submit" disabled={loading}>
        Fetch Token
      </Button>
     
    </Form>
  );
};
