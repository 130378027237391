import React, { useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Button, Form } from "react-bootstrap";
import {
  getAccount,
  validateFlutterwave,
  getAccountBySerial,
} from "./../../services/billingApi";
import { TokenForm } from "./TokenForm";
var ls = require("local-storage");

const TITLE =
  process.env.REACT_APP_IS_TEST === "0" ? "Energy Topup" : "Test Energy Topup";

const AGENT = "agent";
const CUSTOMER = "customer";
const CUSTOMER_METER_NUMBER = "customer_with_meter";
const CUSTOMER_ACCOUNT_NUMBER = "customer_with_account_number";

export const PaymentForm = () => {
  let getUserCategory = () => {
    let category = process.env.REACT_APP_USER_CATEGORY;
    if (category === AGENT) {
      return AGENT;
    }
    if (category === CUSTOMER) {
      const saved = ls.get("userCategory");
      //we were saving agents before, so just to prevent agent from being returned here.
      if (
        saved === CUSTOMER_ACCOUNT_NUMBER ||
        saved === CUSTOMER_METER_NUMBER
      ) {
        return saved;
      }
      return CUSTOMER_ACCOUNT_NUMBER;
    }

    return AGENT;
  };
  //get any saved states or fill transaction data with empty values.
  const [transactionData, setTransactionData] = useState({
    phoneNumber: ls.get("phone") ? ls.get("phone") : "",
    accountNumber: ls.get("account") ? ls.get("account") : "",
    amount: 0,
    // userCategory: ls.get("userCategory") ? ls.get("userCategory") : AGENT,
    userCategory: getUserCategory(),
    meterNumber: ls.get("meterNumber") ? ls.get("meterNumber") : "",
  });
  const [message, setMessage] = useState("");
  const [showToken, setShowToken] = useState(false);

  if (message.length > 0) {
    return (
      <>
        <p>{message}</p>
        <Button onClick={reset}>OK</Button>
      </>
    );
  }

  if (showToken) {
    return <TokenForm setShow={setShowToken} setMessage={setMessage} />;
  }

  //transaction.name indicates the transaction data had been loaded
  //if no name, load user data.
  if (!transactionData.name) {
    return (
      <PaymentStart
        setMessage={setMessage}
        transactionData={transactionData}
        setTransactionData={setTransactionData}
      />
    );
  }

  function reset() {
    setMessage("");
    setTransactionData({ ...transactionData, name: "" });
  }

  return (
    <PaymentConfirm
      transactionData={transactionData}
      reset={reset}
      setMessage={setMessage}
      setShowToken={setShowToken}
    />
  );
};

/**
 * First page of transaction
 * @param {*} param0
 * @returns
 */
const PaymentStart = ({ transactionData, setTransactionData, setMessage }) => {
  const [phoneNumber, setPhoneNumber] = useState(transactionData.phoneNumber);
  const [accountNumber, setAccountNumber] = useState(
    transactionData.accountNumber,
  );
  const [meterNumber, setMeterNumber] = useState(transactionData.meterNumber);
  const [amount, setAmount] = useState(transactionData.amount);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ phone: "", account: "", amount: "" });
  const [refresh, setRefresh] = useState(false);
  const [userCategory, setUserCategory] = useState(
    transactionData.userCategory,
  );

  const checkErrors = function () {
    //phone number sample 09162230998
    let hasError = false;
    //phone number should be 13 characters
    if (phoneNumber.length < 11) {
      //TODO if used in another country, we will need to update this code
      //to use the locale instead, now this is just for Nigeria.
      setErrors(
        Object.assign(errors, {
          phone: "Please enter a valid phone number e.g 07000111222",
        }),
      );
      hasError = true;
    } else {
      //clear phone errors
      setErrors(Object.assign(errors, { phone: "" }));
    }

    //validate account number only if the user is an agent
    if (
      (userCategory === AGENT || userCategory === CUSTOMER_ACCOUNT_NUMBER) &&
      accountNumber.length < 8
    ) {
      //interestingly, object assign does not trigger a state change
      //https://stackoverflow.com/questions/61478050/reactjs-updating-array-inside-object-state-doesnt-trigger-re-render
      setErrors(
        Object.assign(errors, {
          account: "Account Number Should have 8 characters",
        }),
      );
      hasError = true;
    } else {
      //clear account errors
      setErrors(Object.assign(errors, { account: "" }));
    }

    //validate meter serial when the user is customer
    if (userCategory === CUSTOMER_METER_NUMBER && meterNumber.length < 5) {
      //interestingly, object assign does not trigger a state change
      //https://stackoverflow.com/questions/61478050/reactjs-updating-array-inside-object-state-doesnt-trigger-re-render
      setErrors(
        Object.assign(errors, {
          meter_serial: "Meter Number Should have at least 5 letters",
        }),
      );
      hasError = true;
    } else {
      //clear account errors
      setErrors(Object.assign(errors, { meter_serial: "" }));
    }
    if (amount < 6) {
      setErrors(
        Object.assign(errors, { amount: "Amount should be more than 5NGN" }),
      );
      hasError = true;
    } else {
      //clear amount errors
      setErrors(Object.assign(errors, { amount: "" }));
    }
    return hasError;
  };

  //Get account details by account number from db
  const checkAccount = async (e) => {
    e.preventDefault();
    try {
      //check if form has any errors
      if (checkErrors()) {
        //trigger refresh
        setRefresh(!refresh);
        return;
      }
      setLoading(true);

      //generate reference from timestamp and account number or meter number
      let ref;
      if (accountNumber && accountNumber.length > 0) {
        ref = accountNumber + Date.now().toString();
      } else if (meterNumber && meterNumber.length > 0) {
        ref = meterNumber + Date.now().toString();
      } else {
        ref = Date.now().toString();
      }

      const data = {
        ref,
        amount: +amount,
        phoneNumber,
        userCategory,
      };
      setTransactionData(data);
      let resp;
      if (userCategory === AGENT || userCategory === CUSTOMER_ACCOUNT_NUMBER) {
        resp = await getAccount(accountNumber);
      } else {
        resp = await getAccountBySerial(meterNumber);
      }

      if (resp.data.data) {
        setTransactionData({
          ...data,
          name: resp.data.data.customer_name,
          accountNumber: resp.data.data.account_number,
          meterNumber: resp.data.data.meter_serial,
        });
        //save the values to local storage if different
        if (ls.get("userCategory") !== userCategory) {
          ls.remove("userCategory");
          ls("userCategory", userCategory);
        }
        if (ls.get("meterNumber") !== meterNumber) {
          ls.remove("meterNumber");
          ls("meterNumber", meterNumber);
        }
        if (ls.get("phone") !== phoneNumber) {
          ls.remove("phone");
          ls("phone", phoneNumber);
        }

        if (ls.get("account") !== accountNumber) {
          ls.remove("account");
          ls("account", accountNumber);
        }

        ls("account", accountNumber);
        ls("reference", ref);
      } else {
        //no data found for account or meter
        if (
          userCategory === AGENT ||
          userCategory === CUSTOMER_ACCOUNT_NUMBER
        ) {
          setMessage(
            `Account ${accountNumber} was not found, please check and retype`,
          );
        } else {
          setMessage(
            `Meter Number ${meterNumber} was not found, please check and retype`,
          );
        }
      }
    } catch (e) {
      setMessage(e.message);
    }
    setLoading(false);
  };

  const options = [
    { name: "Account number", value: CUSTOMER_ACCOUNT_NUMBER },
    { name: "Meter number", value: CUSTOMER_METER_NUMBER },
  ];

  return (
    <Form onSubmit={checkAccount}>
      {userCategory !== AGENT ? (
        <Form.Group>
          <Form.Label>Pay with</Form.Label>
          <Form.Control
            className="mb-1"
            id="user_category"
            value={userCategory}
            onChange={(e) => setUserCategory(e.target.value)}
            as="select"
          >
            {options.map((option) => {
              return <option value={option.value}>{option.name} </option>;
            })}
          </Form.Control>
        </Form.Group>
      ) : (
        ""
      )}

      <Form.Group>
        <Form.Label>Phone Number</Form.Label>
        {/* Show phone errors if any */}
        <FormErrors error={errors.phone}></FormErrors>
        <Form.Control
          required
          placeholder="(e.g 07000111222)"
          type="phone"
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          value={phoneNumber}
        ></Form.Control>
      </Form.Group>

      {userCategory === AGENT || userCategory === CUSTOMER_ACCOUNT_NUMBER ? (
        <Form.Group id="agent_group">
          <Form.Label>Account Number</Form.Label>
          <FormErrors error={errors.account}></FormErrors>
          <Form.Control
            required
            type="text"
            placeholder="e.g. 41110001"
            onChange={(e) => {
              setAccountNumber(e.target.value);
            }}
            value={accountNumber}
          />
        </Form.Group>
      ) : (
        <Form.Group id="customer_group">
          <Form.Label>Meter Number</Form.Label>
          <FormErrors error={errors.meter_serial}></FormErrors>
          <Form.Control
            required
            type="text"
            placeholder="e.g 1001824"
            onChange={(e) => {
              setMeterNumber(e.target.value);
            }}
            value={meterNumber}
          ></Form.Control>
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label>Amount(NGN)</Form.Label>
        <FormErrors error={errors.amount}></FormErrors>
        <Form.Control
          required
          type="number"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          value={amount}
        ></Form.Control>
      </Form.Group>
      <Button disabled={loading} type="submit">
        {loading ? "Please wait.." : "Continue"}
      </Button>
      <div className="mt-4 mb-1">
        Contact <a href="mailto:cxng@powergen-re.com ">cxng@powergen-re.com </a> for any support
      </div>

    </Form>
  );
};

/**
 * Hook to confirm payment and initiate flutterwave payment
 * @param {*} param0
 * @returns
 */
const PaymentConfirm = ({
  transactionData,
  setMessage,
  reset,
  setShowToken,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const cleanPhoneNumber = (phone) => {
    if (phone.charAt(0) === "0") {
      return "234" + phone.substr(1);
    } else if (
      phone.substr(0, 3).includes("234") ||
      phone.substr(0, 1).includes("+")
    ) {
      return phone;
    } else {
      return "234" + phone;
    }
  };

  /* 
    getFlutterPublicKey: This is a simple function that separates payments between PowerGen Interconnected Energy Limited (PIEL) and PowerGen Nigeria Assets Limited (PNAL).
    At the moment, we have only one site (i.e. Toto site) owned by PIEL, and the rest are owned by PNAL. If a new site needs to be added, the code base needs to be updated accordingly.
    NOTE: This is the simplest approach to solve the problem for now, the long-term solution will be addressed in the new app -> https://mobile.amini.io
   */
  const getFlutterPublicKey = (accountNo) => {    
    const toto_code = "4014"  // PowerGen Interconnected Energy Limited (Toto) 
    let site_code = accountNo.substring(0,4)
    if (process.env.REACT_APP_IS_TEST === "1") {
      return "FLWPUBK_TEST-cc2e09d17db232fbabe031d14de99042-X"
    } 
    if (site_code === toto_code){
      return "FLWPUBK-3cc14557219132bbbc9225b39ce8aa62-X"
    }
    return "FLWPUBK-724737372c7e19c0d549232c060bd80d-X"
  }

  const config = {
    public_key: getFlutterPublicKey(transactionData.accountNumber),
    tx_ref: transactionData.ref,
    amount: transactionData.amount,
    currency: "NGN",
    // payment_options: 'card,ussd', configure at https://dashboard.flutterwave.com/dashboard/settings/accounts
    customer: {
      email: "software@powergen-re.com",
      phonenumber: cleanPhoneNumber(transactionData.phoneNumber),
      name: transactionData.name,
    },
    customizations: {
      title: TITLE,
    },

    text: "Pay with Flutterwave!",
  };

  const handleFlutterPayment = useFlutterwave(config);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      transactionData.phoneNumber = cleanPhoneNumber(
        transactionData.phoneNumber,
      );
      await validateFlutterwave(transactionData);
      handleFlutterPayment({
        callback: (response) => {
          closePaymentModal(); // this will close the modal programmatically
          setIsLoading(false);
          if (transactionData.userCategory !== AGENT) {
            setShowToken(true);
            return;
          }

          setMessage("Successful, thanks for topping up!");
        },
      });
    } catch (e) {
      setMessage(e.message);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <h5>Please confirm the following details:</h5>
      <div>
        <b>Name: </b>
        {transactionData.name}
      </div>

      {/* show meter serial only if the transaction is customer transaction */}
      {transactionData.userCategory === CUSTOMER_METER_NUMBER ||
      transactionData.userCategory === CUSTOMER_ACCOUNT_NUMBER ? (
        <div>
          <b>Meter Number: </b>
          {transactionData.meterNumber}
        </div>
      ) : (
        <span />
      )}
      <div>
        <b>Account: </b>
        {transactionData.accountNumber}
      </div>
      <div>
        <b>Phone: </b>
        {transactionData.phoneNumber}
      </div>
      <div>
        <b>Amount: </b>NGN {transactionData.amount}
      </div>
      <Button type="submit" disabled={isLoading} className="mr-3 my-3">
        Pay with Flutterwave
      </Button>
      <Button onClick={reset}>Cancel</Button>
    </Form>
  );
};

export function FormErrors({ error }) {
  if (error) {
    return (
      <div className="mt-1 mb-1" style={{ color: "red" }}>
        {error}
      </div>
    );
  } else {
    return <br />;
  }
}
