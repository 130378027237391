import axios from "axios";

const meteringApi = axios.create();

meteringApi.defaults.withCredentials = true;
meteringApi.defaults.validateStatus = (status) => {
  return status <= 400; // don't throw error with 400 or below
};
meteringApi.defaults.headers = {
  Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
};

const meteringURL =
  process.env.REACT_APP_IS_TEST === "0"
    ? "https://ng.powergen-re.net/metering/api"
    : "https://test.powergen-re.net/metering/api";

export function getBalanceByAccount(account) {
  return meteringApi.get(`${meteringURL}/balance`, {
    params: { accountno: account },
  });
}

export function getBalanceBySerial(serial) {
  return meteringApi.get(`${meteringURL}/balance`, {
    params: { meter: serial },
  });
}
